.advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  margin: 0 auto;
  padding-bottom: 48px;
}

.advantages__title {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 54px;
  color: #000;
  margin: 0;
  padding: 76px 0 66px;
}

.advantages__grid-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, 372px);
  grid-template-rows: repeat(auto-fill, 335px);
  grid-auto-rows: 335px;
  grid-auto-columns: 372px;
  grid-gap: 28px 28px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  list-style-type: none;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .advantages__title {
    font-size: 24px;
    line-height: 28px;
    padding: 46px 0 40px
  }
}
