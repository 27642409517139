.footer {
  /* height: 154px; */
  width: 100%;
  background: linear-gradient(1.9deg, #1cd8e9 1.55%, #2532de 98.45%);
  box-sizing: border-box;
  padding: 10px 0px;
}

.footer_logo_left{
  width: 30px;
    height: 30px;
    margin-left: 89px;
}
.footer_logo_right_tel_map_email{
    /* width: 24px; */
    height: 20px;
    /* margin-left: 89px; */
    margin-top: 4px;
}

.footer_text_name_left{
  margin-left: 8px!important;
  margin-top: 8px!important;
}
.footer__privacy-text_copirite{
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  padding: 10px 0px;
  text-align: right;
  color: #ffffff;
  margin-right: 8%;
  flex: 1 1;
}

.footer__adress_new {
  font-family: "Raleway", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    /* text-align: center; */
    color: #ffffff;
    margin: 0;
}

.footer__tel_email {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  /* margin-left: 5px; */
}

.footer__links-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  width: 180px;
  justify-content: space-between;
}

.footer__company-info-section{
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
}

.footer__container {
  /* max-width: calc(100%/3); */
  flex:1 1;
  margin-left: 8px;
}

.center{
  flex:1;
  display: flex;
  justify-content:center;
}

.footer__copyright {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.footer__adress {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.footer__link-icon {
  width: 40px;
  height: 40px;
}

.footer__site-logo {
  width: 100px;
  height: 100px;
}

.footer__privacy-link-container{
  display: flex;
  width: 90%;
  /* margin-left: 7%; */
  margin-top: 2%;
  /* text-align: center; */
  margin-left: 69px;
}
@media screen and (max-width: 950px) {
  .footer__privacy-link-container{
    display: contents;
    width: 90%;
    /* margin-left: 7%; */
    margin-top: 2%;
    /* text-align: center; */
    margin-left: 69px;
  }
  .footer {
    text-align: center;
  }
  .footer__container {
    display: block!important;
  }
  .footer__privacy-link{
    margin-left: 0!important;
  }
  .footer__privacy-text_copirite{

    text-align: center;
    margin-right: 0%;
  }
  .footer_logo_left{
    width: 30px;
      height: 30px;
      margin-left: 0px;
  }
}

.footer__privacy-link{
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  padding: 10px 0px;
  /* text-align: center; */
  color: #ffffff;
  text-decoration: underline;
  margin-left: 98px;
  width: 30%;
}

@media screen and (max-width: 900px) {
  .footer {
    /* height: 120px; */
    padding: 10px 76px;
  }
  .footer__privacy-link{
    margin-left: 0!important;
  }
}

@media screen and (max-width: 700px) {
  .footer {
    /* height: 90px; */
    padding: 10px 14px;
  }

  .footer__links-list {
    width: 120px;
  }

  .footer__link-icon {
    width: 30px;
    height: 30px;
  }

  .footer__site-logo {
    width: 60px;
    height: 60px;
  }

  .footer__copyright {
    font-size: 12px;
    line-height: 14px;
  }
  .footer__adress {
    font-size: 12px;
    line-height: 21px;
  }
}

@media screen and (max-width: 414px) {
  .footer__links-list {
    width: 90px;
  }

  .footer__link-icon {
    width: 20px;
    height: 20px;
  }
}
