.offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../vendor/images/offerbg.png) center no-repeat;
  background-size: cover;
  margin-top: -108px;
  box-sizing: border-box;
  height: 1068px;
  padding-bottom: 318px;
  transition: all 0.3s ease-in-out;
}

.offer_short {
  padding-bottom: 278px;
  transition: all 0.3s ease-in-out;
}

.offer__title {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 54px;
  color: #ffffff;
  max-width: 700px;
  text-align: center;
  margin: 0;
}

.offer__desctiption {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0;
  padding: 22px 0 24px;
}

.offer__button {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  background: #fff;
  color: #3f56f1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 252px;
  height: 72px;
  transition: all 0.3s ease-in-out;
}

.offer__button:hover {
  opacity: 0.75;
}

@media screen and (max-width: 767px) {
  .offer_short {
    padding-bottom: 230px;
  }

  .offer__title {
    font-size: 24px;
    line-height: 28px;
    max-width: 400px;
    padding: 0 20px;
  }

  .offer__desctiption {
    font-size: 16px;
    line-height: 28px;
    max-width: 300px;
    padding: 22px 0 42px;
  }
}

@media screen and (max-width: 625px) {
  .offer {
    margin-top: -108px;
    height: 932px;
    padding-bottom: 330px;
  }
  .offer_short {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 442px) {
  .offer {
    background: url(../vendor/images/mobileofferbg.png) center no-repeat;
    background-size: cover;
    margin-top: -108px;
    height: 932px;
    padding-bottom: 330px;
  }

  .offer_short {
    padding-bottom: 40px;
  }
}
