.cookie-alert-container{
    box-sizing: border-box;
    position: fixed;
    width: 600px;
    height: 180px;
    font-family: "Raleway", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    background-color: rgb(40, 40, 40);
    border-radius: 3px;
    padding: 20px;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    transition: 0.3s;
}

.cookie-alert-text{
    width: 100%;
    padding-bottom: 10px;
    color: #e7e7e7;
}
@media screen and (max-width: 900px) {
    .cookie-alert-button{
        top: 82%;
        right: 2%;
    }
    .cookie-alert-text{
        width: 100%;
        padding-bottom: 10px;
        color: #e7e7e7;
    }
    .cookie-alert-container{
        font-size: 14px
    }
  }

.cookie-alert-button{
    background-color: rgb(18, 79, 148);
    color: #e7e7e7;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    margin: -2% 0% 0% 87%;
    position: absolute;
}

.hiden{
    transform: translateX(927px);
}

@media screen and (max-width: 700px) {
    #cookie-alert-container{
        width: 100%;
        bottom: 0;
        right: 0;
        border-radius: 0;
        padding: 10px;
    }

    .cookie-alert-button{
        padding: 10px 20px;
    }
}

@media screen and (max-width: 415px) {
    #cookie-alert-container{
        width: 100%;
        bottom: 0;
        right: 0;
        border-radius: 0;
        padding: 10px;
    }

    .cookie-alert-button{
        padding: 10px 20px;
    }
}