.partners {
  width: 100%;
  padding: 40px 0 120px;
}

.partners__title {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 54px;
  color: #000000;
  margin: 0;
  text-align: center;
  padding-bottom: 40px;
}

.partners__list {
  display: flex;
  margin: 0 auto;
  list-style-type: none;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.partners__logo {
  transition: 0.3s ease-in-out;
  cursor: pointer;
  height: 80px;
  margin: 20px 20px 20px;
}

.partners__logo:hover {
  filter: drop-shadow(0px 0px 20px blue);
}

@media screen and (max-width: 900px) {
  .partners {
    padding: 40px 0 10px;
  }

  .partners__title {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 42px;
  }

  .partners__logo {
    height: 40px;
    margin: 20px 20px 20px;
  }
}
