.popup {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: .3s linear;
}

.popup_opened {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s linear;
}

.popup__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  left: 0;
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 430px;
  padding-bottom: 40px;
  padding-top: 34px;
}

.popup__info {
  width: 120px;
  height: 120px;
  margin-top: 26px;
}

.popup__title {
  width: 83%;
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000;
  margin: 0;
  padding-top: 32px;
  padding-bottom: 22px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .popup__container {
    width: 300px;
    padding-bottom: 28px;
  }

  .popup__title {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }
}
