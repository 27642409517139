.fwm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 106px;
  box-sizing: border-box;
  padding-top: 50px;
}

.fwm__map {
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
  border-radius: 24px;
  background: url("../vendor/images/mapbg.jpg") no-repeat center;
  background-size: cover;
  width: 450px;
  height: 550px;
  margin-left: 44px;
}

.fwm__form {
  max-width: 800px;
  padding: 0 40px;
  box-sizing: border-box;
}

.fwm__form-title {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #111111;
  margin: 0;
  padding-bottom: 34px;
}

.form {
  display: grid;
  grid-template-areas:
    "name phone"
    "about about"
    "button .";
  /* max-width: 800px; */
  grid-gap: 24px 24px;
  width: 100%;
}
#about-input {
  grid-area: about;
}
#phone-input {
  grid-area: phone;
}
#name-input {
  grid-area: name;
}
#form-button {
  grid-area: button;
}

.form__input {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border: none;
  outline: none;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  box-sizing: border-box;
  padding: 0 16px;
}

.form__input_textarea {
  height: 170px;
  padding-top: 16px;
}

.form__button {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  background: #2532de;
  color: #fff;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  height: 72px;
  transition: all 0.3s ease-in-out;
  justify-self: center;
}

.form__button_disabled {
  background: #e5e5e5;
  color: #2532de;
}

.form__button:hover {
  opacity: 0.75;
}

.form__input-field {
  display: flex;
  flex-direction: column;
}

.form__label {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  padding-bottom: 12px;
}

.form__label > span {
  color: #2532de;
}
.form__req-container {
  display: flex;
  margin: 12px 0 0 12px;
}

.form__star {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  margin: 0;
}

.form__req-container > span {
  color: #2532de;
  margin: 0;
  padding: 0;
}

.privacy-notification
{
  padding-top: 10px;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-size: 14px;
}

.privacy-notification a{
  font-weight: bold;
}

@media screen and (max-width: 1400px) {
  .fwm__map {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .fwm__form-title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .form {
    grid-template-areas:
      "name"
      "phone"
      "about"
      "button";
    padding: 0;
    box-sizing: border-box;
  }

  .form__input {
    height: 60px;
  }

  .form__input_textarea {
    height: 170px;
    padding-top: 16px;
  }

  .form__req-container {
    margin: 12px auto 0;
  }

  .form__star {
    font-size: 16px;
  }

  .fwm {
    padding-top: 30px;
  }
}

@media screen and (max-width: 500px) {
  .form__input {
    font-size: 16px;
  }
}

.circle-preloader {
  display: block;
  width: 20px;
  height: 20px;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
  margin: 0 auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
