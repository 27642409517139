.slick-dots li.slick-active button::before {
  color: #3f56f1;
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 12px;
}

.slick-dots_white li.slick-active button::before {
  color: #fff;
  opacity: 1;
}