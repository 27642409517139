.pluses {
  height: 1270px;
  box-sizing: border-box;
  background: url(../vendor/images/plusesbg3.png) no-repeat center;
  background-size: cover;
  display: grid;
  grid-template-columns: repeat(3, 274px);
  grid-template-rows: repeat(3, 250px);
  grid-auto-rows: 250px;
  grid-auto-columns: 274px;
  grid-gap: 30px 28px;
  grid-template: "allUneed allUneed ." 250px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 200px 0;
  position: relative;
}
.pluses__anchor {
  top: 138px;
  left: 0;
  position: absolute;
}

.pluses__needs {
  grid-area: allUneed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  justify-content: center;
}

.pluses__title {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 54px;
  color: #ffffff;
  margin: 0;
  padding-bottom: 26px;
}

.pluses__subtitle {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  margin: 0;
}

.pluses__card {
  background: #fff;
  border-radius: 15px;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  padding: 28px 34px;
}

.pluses__card-text {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #111111;
}

.pluses__circle {
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background: linear-gradient(13.24deg, #1cd8e9 7.28%, #2532de 92.72%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.pluses__circle-number {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
}

.pluses__circle-number_dots {
  padding-bottom: 12px;
}

@media screen and (min-width: 1600px) {
  .pluses {
    background: url(../vendor/images/plusesbigbg.png) no-repeat center;
  }
}

@media screen and (max-width: 900px) {
  .pluses {
    height: 1270px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    padding: 128px 30px;
    grid-gap: 0;
  }

  .pluses__anchor {
    top: 100px;
    position: absolute;
  }

  .pluses__title {
    font-size: 32px;
    line-height: 28px;
    padding-bottom: 42px;
    text-align: center;
  }

  .pluses__subtitle {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .pluses__needs {
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
  }

  .pluses__slider_container {
    width: 274px;
  }

  .pluses__card {
    height: 250px;
    margin: 30px auto;
  }
  .pluses__image {
    margin-top: 80px;
    max-width: 320px;
    height: auto;
  }
}
@media screen and (max-width: 500px) {
  .pluses__title {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 42px;
    text-align: center;
  }

  .pluses__subtitle {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }
}
