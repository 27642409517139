.header {
  box-sizing: border-box;
  display: flex;
  padding: 32px 50px 0;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  /* так как box-sizing: border-box, height 36px надо прибавить padding */
  height: 68px;
}

.header__logo {
  margin: 0;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
}

.header__list {
  display: flex;
  transition: all 0.3s ease-in-out;
}

.header__link {
  margin-left: 50px;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  background-image: linear-gradient(#fff, #fff);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
}

.header__link:hover {
  background-size: 100% 1px;
}

.header__burger-button {
  display: none;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 0;
  height: 30px;
  width: 30px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.header__burger-button:hover {
  opacity: 0.75;
}

.header__burger-button span {
  background-color: #fff;
  display: block;
  height: 3px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  position: absolute;
}
.header__burger-button span:after {
  position: absolute;
  background-color: #fff;
  content: "";
  display: block;
  height: 3px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  top: 8px;
}

.header__burger-button span:before {
  position: absolute;
  background-color: #fff;
  content: "";
  display: block;
  height: 3px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  top: -8px;
}

.header__burger-button_active > span {
  transform: rotate(45deg);
}
.header__burger-button_active > span::before {
  top: 0;
  transform: rotate(0);
}
.header__burger-button_active > span::after {
  top: 0;
  transform: rotate(90deg);
}

@media screen and (max-width: 900px) {
  .header {
    padding: 32px 50px 0;
  }

  .header__list {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    top: 68px;
    height: 0;
    overflow: hidden;
    width: 100%;
  }

  .header__list li {
    margin: 10px 0;
  }

  .header__list_active {
    height: 160px;
  }

  .header__navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .header__burger-button {
    display: block;
  }

  .header__link {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 30px 26px 0;
  }
  .header__list {
    top: 98px;
  }
}
