.advantages__card {
  width: 372px;
  height: 336px;
  box-shadow: 0px 0px 51px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 12px 12px;
}

.advantages__card-image {
  padding-top: 38px;
}

.advantages__card-title {
  margin: 0;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #111111;
  text-align: center;
  padding: 24px 0 36px;
}

.advantages__card-description {
  margin: 0;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #111111;
  text-align: center;
}

@media screen and (max-width: 1172px) {
  .advantages__card {
    width: 300px;
    height: 270px;
    margin: 30px auto;
  }
  .advantages__card-title {
    font-size: 20px;
    line-height: 23px;
  }

  .advantages__card-description {
    font-size: 16px;
    line-height: 26px;
  }
}
