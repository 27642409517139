@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

html {
  scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

body {
	min-height: 100vh;
  box-sizing: border-box;
}

::-webkit-scrollbar-button {
  background-repeat:no-repeat;
  width:5px;
  height:0px
  }

  ::-webkit-scrollbar-track {
  background-color:#fff
  }

  ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background-color:#6dc0c8;
  }

  ::-webkit-scrollbar-thumb:hover{
  background-color:#56999f;
  }

  ::-webkit-scrollbar{
  width: 4px;
  }
