@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./Raleway-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./Raleway-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./Raleway-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./Raleway-Black.ttf") format("ttf");
}
