.delUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../vendor/images/offerbg.png) center no-repeat;
  background-size: cover;
  margin-top: -182px;
  box-sizing: border-box;
  height: 200px;
  padding-bottom: 300px;
  transition: all 0.3s ease-in-out;
}

.delUserForms {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
}

.delUserForm {
  max-width: 750px;
  padding: 0 40px;
  box-sizing: border-box;
}

.delUserFormTitle {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #111111;
  margin: 0;
  padding-bottom: 34px;
  text-align: center;
}

.delUserFormBody {
  display: flex;
  /* grid-template-areas:
      "name phone"
      "about about"
      "button ."; */
  /* max-width: 800px; */
  grid-gap: 24px;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.delUserFormBodyInputField {
  display: flex;
  flex-direction: column;
}

.delUserFormBodyLabel {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  padding-bottom: 12px;
}

.delUserFormBodyInput {
  width: 300px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  border: none;
  outline: none;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  box-sizing: border-box;
  padding: 0 16px;
}

.delUserFormBodyReq {
  display: flex;
  margin: 12px 0 0 12px;
}

.delUserFormBodyReqText {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #000000;
  margin: 0;
}

.delUserFormBodyButton {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  background: #2532de;
  color: #fff;
  border-radius: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  height: 72px;
  transition: all 0.3s ease-in-out;
  justify-self: center;
}

.delUserFormBodyButtonDisabled {
  background: #e5e5e5;
  color: #2532de;
}

.delUserFormBodyButtonText {
  display: block;
  width: 20px;
  height: 20px;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
  margin: 0 auto;
}

.delUserFormBodyPrivacyNotification {
  padding-top: 10px;
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-size: 14px;
}

.delUserFormBodyPrivacyNotification a {
  font-weight: bold;
}
