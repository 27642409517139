.about {
  box-sizing: border-box;
  display: grid;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    "cost cost"
    ". ."
    "button button";
  grid-template-columns: repeat(2, minmax(350px, 1fr));
  grid-gap: 30px 0;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 40px;
}

.about__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
  height: auto;
  justify-self: center;
}

.about__container_buttons {
  align-items: center;
  width: 340px;
  height: auto;
}

.about__container_buttons img {
  width: 100%;
  height: auto;
}

.about__qr-text {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin: 16px 0 0 0;
}

/* .about__qr-text>span {
  color: #244adf;
} */

.about__container_cost {
  width: 367px;
  align-self: flex-start;
}

.about__cost-anchor {
  top: 0px;
  left: 0;
}

.about__title {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  color: #000000;
  margin: 0;
  padding-bottom: 32px;
}

.about__title_cost {
  grid-area: cost;
  width: 100%;
  text-align: center;
}

.about__image {
  align-self: center;
  height: auto;
  width: 100%;
}

.about__image_cost {
  width: 150px;
  margin-bottom: 30px;
}

.about__paragraph {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin: 0;
  padding-bottom: 20px;
}

.about__paragraph_cost {
  text-align: center;
}

.about__paragraph > span {
  font-weight: 700;
}

.about__check {
  color: #244adf;
}

.about__button-container {
  grid-area: button;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.about__button {
  font-family: "Raleway", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  background: #2532de;
  color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 252px;
  height: 72px;
  transition: all 0.3s ease-in-out;
}

.about__button:hover {
  opacity: 0.75;
}

@media screen and (max-width: 900px) {
  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 0;
    padding: 40px 24px;
  }

  .about__iphone {
    display: none;
  }

  .about__container {
    align-items: center;
    padding: 0 0 40px 0;
    max-width: 400px;
  }

  .about__container_cost {
    width: 100%;
    align-self: center;
  }

  .about__title {
    font-size: 24px;
    text-align: center;
  }

  .about__paragraph {
    font-size: 18px;
    padding-bottom: 0;
    text-align: center;
  }

  .about__paragraph_check {
    padding-bottom: 12px;
  }

  .about__button-container {
    padding-top: 0;
  }

  .about__image_cost {
    max-width: 100px;
  }
}

@media screen and (max-width: 500px) {
  .about__container_buttons {
    width: 200px;
  }

  .about__qr-text {
    font-size: 12px;
    line-height: 16px;
    margin: 8px 0 0 0;
  }
}
